<template>
  <div v-if="show" class="background">
    <span class="title">{{ $t('age_agreement.title') }}</span>
    <img class="logo" src="../../assets/img/viewchat_logo.png">
    <span class="text1">
      {{ $t('age_agreement.text1') }}
    </span>
    <span class="text2" v-html="$t('age_agreement.text2')" />
    <div class="btn-group">
      <button @click="onClose">{{ $t('age_agreement.reject') }}</button>
      <button @click="onAgree">{{ $t('age_agreement.agree') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgeAgreement',
  components: {},
  props: ["show"],
  emits: ['close', 'agree'],
  data() {
    return {
    };
  },
  methods: {
    onAgree() {
      this.$emit('agree');
    },

    onClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
div:not(.carousel__viewport) {
  display: flex;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fcf8f4;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    color: #282828;
    font-weight: bold;
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .logo {
    width: 140px;
    height: 140px;
    margin-top: 50px;

    @media (max-width: 768px) {
      width: 70px;
      height: 70px;
      margin-top: 30px;
    }
  }

  .text1 {
    color: #282828;
    font-weight: bold;
    font-size: 26px;
    margin-top: 30px;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-top: 20px;
    }
  }

  .text2 {
    color: #282828;
    font-size: 22px;
    margin-top: 20px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 12px;
    }
  }

  .btn-group {
    height: 65px;
    margin-top: 50px;

    @media (max-width: 768px) {
      height: 40px;
      margin-top: 36px;
    }

    button {
      flex: 1;
      width: 200px;
      height: 100%;
      outline: none;
      font-size: 24px;

      @media (max-width: 768px) {
        width: 150px;
        font-size: 16px;
      }
    }

    >button {
      background-color: #282828;
      color: #ffffff;
      margin-right: 16px;

      @media (max-width: 768px) {
        margin-right: 7px;
      }
    }

    button+button {
      background-color: #f25c62;
      color: #ffffff;
    }
  }
}
</style>
