<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_emaillogin" @click="$event.stopPropagation()">
        <div class="head">
          <h1>{{ $t('splash.view_chat_login') }}</h1>
          <p>{{ $t('login.guide') }}</p>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="input">
          <ul>
            <li><input type="text" v-model="id" :placeholder="$t('login.input_id')"></li>
            <li><input type="password" v-model="pwd" :placeholder="$t('login.input_pwd')"></li>
          </ul>
        </div>
        <div class="btn">
          <a href="javascript:" @click="onLogin">{{ $t('login.login') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./Login.scss"></style>

<script>

import { APP_CLASS } from "@/lib/constant";
import { loadData, requestPost, saveData, showToast } from "@/lib/util";
import fcm from "@/lib/fcm";
import xmpp from "@/lib/xmpp";
var sha256 = require('js-sha256');

export default {
  name: 'LoginDlg',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'login'],
  data() {
    return {
      id: '',
      pwd: '',
      autoLogin: false,
      backButtonRouteGuard: null,
    }
  },
  computed: {},
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        this.id = '';
        this.pwd = '';
        this.autoLogin = false;

        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_emaillogin').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      } else if (e.keyCode == 13) {
        let me = document.querySelector('.pop_emaillogin').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.onLogin();
        }
      }
    },

    onLogin: function () {
      if (this.id === '') {
        showToast(this.$t('signup.msg_input_email'));
        return;
      }

      if (this.pwd === '') {
        showToast(this.$t('signup.msg_input_password'));
        return;
      }

      const pwdHash = sha256(this.pwd);

      //this.$emit('login', this.id, this.pwd);
      this.doLogin(this.id, pwdHash);
    },

    doLogin(loginId, pwdHash) {
      let _app = this;
      const fcmToken = loadData('FCMToken', '');
      requestPost(
        'user/login',
        {
          class: APP_CLASS,
          login_id: loginId,
          password_hash: pwdHash,
          login_type: 1,
          device_id: '',
          gcm_token: fcmToken,
          latitude: 0.0,
          longitude: 0.0
        },
        response => {
          saveData('loginId', loginId);
          saveData('pwdHash', pwdHash);
          
          let NotifySound = loadData('NotifySound', 'on');
          let NotifyVibrate = loadData('NotifyVibrate', 'on');

          response.user_info = Object.assign({}, response.user_info, {
            NotifySound: NotifySound == "on" ? true : false,
            NotifyVibrate: NotifyVibrate == "on" ? true : false,
            user_video_alarm: 0
          });
          _app.$store.dispatch('setMyInfo', response.user_info);
          _app.$store.dispatch('setSettingInfo', response.setting);

          if (response.user_info.user_sex == 1) {
            fcm.subscribe('server_message_female');
            fcm.unsubscribe('server_message_male');
            fcm.subscribe(`server_message_${APP_CLASS}_female`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male`);
            fcm.subscribe(`server_message_${APP_CLASS}_female_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male_kr`);
          } else {
            fcm.subscribe('server_message_male');
            fcm.unsubscribe('server_message_female');
            fcm.subscribe(`server_message_${APP_CLASS}_male`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female`);
            fcm.subscribe(`server_message_${APP_CLASS}_male_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female_kr`);
          }

          xmpp.start();

          _app.$emit('close');

          if (response.user_info.user_status == 1) {
            _app.$router.replace({
              name: 'Profile',
              params: {
                nicknameEvent: true
              }
            });
            return;
          }
          
          _app.$root.onTab(0);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
