<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_member" @click="$event.stopPropagation()">
        <div class="top_member">
          <h1>{{ $t('signup.only_10_sec') }} {{ $t('signup.sign_up') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="box">
          <ul>
            <li>
              <input type="text" v-model="email" :placeholder="$t('signup.id_placeholder')"
                     style="width:55%" :disabled="isEmailVerified">
              <a v-if="!isEmailVerified" href="javascript:" class="st01" @click="onSendCertKey">
                {{ $t('signup.send_cert_key') }}
              </a>
              <a v-else href="javascript:" class="st02" @click="isEmailVerified = false">
                Verified !   Retry?
              </a>
            </li>
            <li v-if="!isEmailVerified">
              <input type="text" v-model="certKey" :placeholder="$t('signup.cert_key_placeholder')">
              <a href="javascript:" class="st02" @click="onCheckCertKey">{{ $t('signup.verify_cert_key') }}</a>
            </li>
            <li>
              <input type="password" v-model="pwd" :placeholder="$t('signup.pwd')">
              <input type="password" v-model="pwd_confirm" :placeholder="$t('signup.pwd_confirm')">
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:" :class="['sex01', {selected: gender == 0}]" @click="gender = 0">
                <img src="../../assets/img/member_male.png">
              </a>
              |
              <a href="javascript:" :class="['sex02', {selected: gender == 1}]" @click="gender = 1">
                <img src="../../assets/img/member_female.png">
              </a>
            </li>
            <li>
              <input type="text" v-model="nickname" :placeholder="$t('signup.nickname_placeholder')"
                     maxlength="10" style="width: 50%">
              <select v-model="age" style="width: 30%">
                <option value="">{{ $t('msg.select_age') }}</option>
                <option v-for="age in ages" :value="age">{{ age }}</option>
              </select>
            </li>
          </ul>
        </div>
        <div class="btn">
          <a href="javascript:" @click="onSignup">{{ $t('signup.start') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./Signup.scss"></style>

<script>
import { loadData, requestPost, saveData, showToast } from "@/lib/util";
import { APP_CLASS } from "@/lib/constant";
import fcm from "@/lib/fcm";
import xmpp from "@/lib/xmpp";
import dao from "@/lib/dao";

var sha256 = require('js-sha256');

export default {
  name: 'Signup',
  components: {},
  props: ["loginId", "show", "partnerType", "partnerId"],
  emits: ['close', 'signup'],
  data() {
    let _app = this;
    return {
      email: '',
      certKey: '',
      isEmailVerified: false,
      pwd: '',
      pwd_confirm: '',
      nickname: '',
      gender: null,
      age: '',
      motto: null,
      agree: false,
      ages: [...Array(41).keys()].map(it => {
        return `${it + 20}`
      }),
      mottos: _app.$store.getters.mottos,
      backButtonRouteGuard: null,
    };
  },
  computed: {
    invalidEmail() {
      return this.email != '' && !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email);
    },
    pwdConfirmed() {
      return this.pwd != '' && this.pwd == this.pwd_confirm;
    }
  },
  watch: {
    show(show) {
      if (show) {
        this.email = '';
        this.certKey = '';
        this.pwd = '';
        this.pwd_confirm = '';
        this.nickname = '';
        this.gender = null;
        this.age = '';
        this.motto = null;
        this.agree = false;
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onGender(event) {
      this.gender = event.target.value;
    },
    onMotto(event) {
      this.motto = event.target.value;
    },
    onAge(event) {
      this.age = event.target.value;
    },
    async onSignup() {
      let _app = this;
      if (!this.loginId) {
        if (!this.isEmailVerified) {
          showToast(this.$t('signup.msg_verify_email'));
          return;
        }
        if (this.pwd === '') {
          showToast(this.$t('signup.msg_input_password'));
          return;
        }
        if (this.pwd !== this.pwd_confirm) {
          showToast(this.$t('signup.msg_confirm_password'));
          return;
        }
      }
      if (_app.nickname == '') {
        showToast(this.$t('msg.input_nickname'));
        return;
      }
      if (_app.age == '') {
        showToast(this.$t('msg.select_age'));
        return;
      }
      if (_app.gender == null) {
        showToast(this.$t('msg.select_sex'));
        return;
      }

      const fcmToken = loadData('FCMToken', '');
      const pwdHash = sha256(this.pwd);

      requestPost(
        'user/signup',
        {
          class: APP_CLASS,
          login_id: _app.loginId ? _app.loginId : this.email,
          password_hash: _app.loginId ? '' : pwdHash,
          login_type: 1,
          device_id: '',
          gcm_token: fcmToken,
          phone_number: '',
          nickname: _app.nickname,
          sex: _app.gender,
          age: _app.age,
          motto: _app.motto,
          profile_image: '',
          profile_video: '',
          area: 0,
          video_alarm: 0,
          latitude: 0.0,
          longitude: 0.0,
          referrer_path: this.partnerType,
          referrer_id: this.partnerId,
          email: this.email,
        },
        response => {
          saveData('loginId', _app.loginId ? _app.loginId : this.email);
          saveData('pwdHash', _app.loginId ? '' : pwdHash);

          _app.$store.dispatch('setMyInfo', Object.assign({}, response.user_info, {
            NotifySound: true,
            NotifyVibrate: true
          }));
          _app.$store.dispatch('setSettingInfo', response.setting);

          if (response.user_info.user_sex == 1) {
            fcm.subscribe('server_message_female');
            fcm.unsubscribe('server_message_male');
            fcm.subscribe(`server_message_${APP_CLASS}_female`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male`);
            fcm.subscribe(`server_message_${APP_CLASS}_female_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male_kr`);
          } else {
            fcm.subscribe('server_message_male');
            fcm.unsubscribe('server_message_female');
            fcm.subscribe(`server_message_${APP_CLASS}_male`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female`);
            fcm.subscribe(`server_message_${APP_CLASS}_male_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female_kr`);
          }

          if (xmpp.connection == null || !xmpp.isConnected) {
            xmpp.start();
          }

          dao.deleteAllMessages();

          _app.$root.onTab(0);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    async onSendCertKey() {
      let _app = this;
      if (_app.email === '') {
        showToast(this.$t('signup.msg_input_email'));
        return;
      }
      if (this.invalidEmail) {
        showToast(this.$t('signup.invalid_id'));
        return;
      }

      requestPost(
        'emailVerify/sendCertKey',
        {
          email: this.email
        },
        response => {
          showToast(this.$t('signup.msg_sent_cert_key'));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    async onCheckCertKey() {
      let _app = this;
      if (_app.certKey === '') {
        showToast(this.$t('signup.msg_input_cert_key'));
        return;
      }

      requestPost(
        'emailVerify/checkCertKey',
        {
          email: this.email,
          cert_key: this.certKey,
        },
        response => {
          this.isEmailVerified = true;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
