<template>
  <div class="warp_login">
    <div class="login_box">
      <div class="login_image">
        <h2>{{ $t('login.title') }}</h2>
        <img src="../../assets/img/login_img01.png">
      </div>
      <div class="login_contents">
        <a href="javascript:" @click="onGotoHome()">
          <img src="../../assets/img/login_logo.png">
        </a>
        <h1>{{ $t('login.login_01') }}</h1>
        <p>{{ $t('login.login_02') }}</p>
        <!-- <div class="google">
          <div id="btGoogleLogin"></div>
        </div> -->
        <div class="btn">
          <a href="javascript:" @click="showLoginDlg = true">
            <img src="../../assets/img/ico_mail.png">{{ $t('splash.view_chat_login') }}
          </a>
          <a href="javascript:" @click="showSignupDlg = true">
            <img src="../../assets/img/ico_signup.png">{{ $t('splash.view_chat_signup') }}
          </a>
        </div>
        <div class="forgot_password">
          <a href="javascript:" @click="showResetPasswordDlg = true"
            style="display:flex;flex-direction: row;align-items: center;padding: 10px 0px 20px;">
            <img src="../../assets/img/forget_password.png" style="margin-right: 5px;">{{ $t('splash.forgot_password') }}
          </a>
        </div>
      </div>
    </div>
    <div class="login_copy">
      <p>{{ $t('header_bar.company_name') }}</p>
      <span>{{ $t('header_bar.boss') }}</span>
      <span>{{ $t('header_bar.biz') }}</span>
      <span>{{ $t('header_bar.southwest') }}</span>
      <span>{{ $t('header_bar.tel') }}</span>
      <span>{{ $t('header_bar.email_prefix') }}@{{ $t('header_bar.email_suffix') }}</span>
      <h5>COPYRIGHT 2023 @ {{ $t('header_bar.company_name') }}. All Rights Reserved.</h5>
    </div>
  </div>

  <LoginDlg :show="showLoginDlg" @close="showLoginDlg = false" @login="showLoginDlg = false" />
  <SignupDlg :show="showSignupDlg" :loginId=null :partnerType="partnerType" :partnerId="partnerId"
    @close="showSignupDlg = false" @signup="showSignupDlg = false" />
  <ResetPasswordDlg :show="showResetPasswordDlg" @close="showResetPasswordDlg = false"
    @reset="showResetPasswordDlg = false" />
  <AgeAgreement :show="showAgeAgreement" @close="showAgeAgreement = false"
    @agree="{ showSignupDlg = true; showAgeAgreement = false; }" />
</template>

<style lang="scss" scoped src="./Login.scss"></style>
<script>
import { APP_CLASS, OFFLINE_MODE, GOOGLE_CLIENT_ID } from "@/lib/constant";
import { loadData, requestPost, saveData, showToast } from "@/lib/util";
import fcm from "@/lib/fcm";
import xmpp from "@/lib/xmpp";
import LoginDlg from "../dialog/Login";
import SignupDlg from "../dialog/Signup";
import ResetPasswordDlg from "../dialog/ResetPassword";
import AgeAgreement from './AgeAgreement.vue';

const { OAuth2Client } = require('google-auth-library')

export default {
  name: 'Login',
  components: {
    LoginDlg,
    SignupDlg,
    ResetPasswordDlg,
    AgeAgreement,
  },
  data() {
    return {
      showLoginDlg: false,
      showSignupDlg: false,
      showAgeAgreement: false,
      showResetPasswordDlg: false,
      partnerType: '',
      partnerId: '',
    }
  },
  mounted() {
    fcm.subscribe('server_message');
    fcm.subscribe('server_message_' + APP_CLASS);
    fcm.subscribe('server_message_' + APP_CLASS + '_kr');

    this.$store.dispatch('loadMyInfo');
    this.doVersionCheck();

    if (this.$route.params.partnerType != '' && this.$route.params.partnerId != '') {
      this.partnerType = this.$route.params.partnerType;
      this.partnerId = this.$route.params.partnerId;
      this.showSignupDlg = true;
    }
  },
  methods: {
    doVersionCheck() {
      this.getUpdateInfo();
    },

    getUpdateInfo() {
      let _app = this;
      requestPost(
        'start/getUpdateInfo',
        {},
        response => {
          if (response != null) {
            _app.$store.dispatch('setUpdateInfo', response);
          }
          this.checkAutoLogin();
        },
        (code, msg) => {
          if (code == -1) {
            this.checkAutoLogin();
          } else {
            showToast(msg);
          }
        }
      )
    },

    checkAutoLogin() {
      if (this.$store.getters.token !== '' && this.$store.getters.me !== null) { // auto login
        let loginId = loadData('loginId', '');
        let pwdHash = loadData('pwdHash', '');
        this.doLoginWithId(loginId, pwdHash);
      } else { // render google login button
        // this.renderGoogleLogin();
      }
    },

    renderGoogleLogin() {
      if (OFFLINE_MODE) {
        return;
      }

      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: this.handleCredentialResponse
      });
      google.accounts.id.renderButton(
        document.getElementById("btGoogleLogin"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    },

    doLoginWithId(loginId, pwdHash) {
      let _app = this;
      const fcmToken = loadData('FCMToken', '');
      requestPost(
        'user/login',
        {
          class: APP_CLASS,
          login_id: loginId,
          password_hash: pwdHash,
          login_type: 1,
          device_id: '',
          gcm_token: fcmToken,
          latitude: 0.0,
          longitude: 0.0
        },
        response => {
          saveData('loginId', loginId);
          saveData('pwdHash', pwdHash);

          let NotifySound = loadData('NotifySound', 'on');
          let NotifyVibrate = loadData('NotifyVibrate', 'on');

          response.user_info = Object.assign({}, response.user_info, {
            NotifySound: NotifySound == "on" ? true : false,
            NotifyVibrate: NotifyVibrate == "on" ? true : false,
            user_video_alarm: 0
          });

          _app.$store.dispatch('setMyInfo', response.user_info);
          _app.$store.dispatch('setSettingInfo', response.setting);

          if (response.user_info.user_sex == 1) {
            fcm.subscribe('server_message_female');
            fcm.unsubscribe('server_message_male');
            fcm.subscribe(`server_message_${APP_CLASS}_female`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male`);
            fcm.subscribe(`server_message_${APP_CLASS}_female_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_male_kr`);
          } else {
            fcm.subscribe('server_message_male');
            fcm.unsubscribe('server_message_female');
            fcm.subscribe(`server_message_${APP_CLASS}_male`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female`);
            fcm.subscribe(`server_message_${APP_CLASS}_male_kr`);
            fcm.unsubscribe(`server_message_${APP_CLASS}_female_kr`);
          }

          xmpp.start();
          if (response.user_info.user_status == 1) {
            _app.$router.replace({
              name: 'Profile',
              params: {
                nicknameEvent: true
              }
            });
            return;
          }

          _app.$root.onTab(0);
        },
        (code, message) => {
          _app.renderGoogleLogin();
        }
      );
    },

    async handleCredentialResponse(response) {
      const user = await this.getDecodedOAuthJwtGoogle(response.credential);
      if (user != null) {
        this.doLoginWithId(user.payload.sub, '');
      }
    },

    async getDecodedOAuthJwtGoogle(token) {
      try {
        const client = new OAuth2Client(GOOGLE_CLIENT_ID)
        const ticket = await client.verifyIdToken({
          idToken: token,
          audience: GOOGLE_CLIENT_ID,
        })
        return ticket
      } catch (error) {
        return null;
      }
    },

    onGotoHome() {
      this.$router.push('/live');
    },
  }
}
</script>
