<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_member" @click="$event.stopPropagation()">
        <div class="top_member">
          <h1>{{ $t('reset_password.title') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="box">
          <ul>
            <li>
              <input type="text" v-model="email" :placeholder="$t('signup.id_placeholder')"
                     style="width:55%" :disabled="isEmailVerified">
              <a v-if="!isEmailVerified" href="javascript:" class="st01" @click="onSendCertKey">
                {{ $t('signup.send_cert_key') }}
              </a>
              <a v-else href="javascript:" class="st02" @click="isEmailVerified = false">
                Verified !   Retry?
              </a>
            </li>
            <li v-if="!isEmailVerified">
              <input type="text" v-model="certKey" :placeholder="$t('signup.cert_key_placeholder')">
              <a href="javascript:" class="st02" @click="onCheckCertKey">{{ $t('signup.verify_cert_key') }}</a>
            </li>
          </ul>
          <ul>
            <li>
              <input class="fill_width" type="password" v-model="pwd" :placeholder="$t('signup.pwd')">
            </li>
            <li>
              <input class="fill_width" type="password" v-model="pwd_confirm" :placeholder="$t('signup.pwd_confirm')">
            </li>
          </ul>
        </div>
        <div class="btn">
          <a href="javascript:" @click="onReset">{{ $t('reset_password.button_title') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./ResetPassword.scss"></style>

<script>
import { requestPost, showToast } from "@/lib/util";
var sha256 = require('js-sha256');

export default {
  name: 'ResetPassword',
  components: {},
  props: ["show"],
  emits: ['close', 'reset'],
  data() {
    let _app = this;
    return {
      email: '',
      certKey: '',
      isEmailVerified: false,
      pwd: '',
      pwd_confirm: '',
      backButtonRouteGuard: null,
    };
  },
  computed: {
    invalidEmail() {
      return this.email != '' && !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email);
    },
    pwdConfirmed() {
      return this.pwd != '' && this.pwd == this.pwd_confirm;
    }
  },
  watch: {
    show(show) {
      if (show) {
        this.email = '';
        this.certKey = '';
        this.pwd = '';
        this.pwd_confirm = '';
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    async onReset() {
      let _app = this;
      if (!this.isEmailVerified) {
        showToast(this.$t('signup.msg_verify_email'));
        return;
      }
      if (this.pwd === '') {
        showToast(this.$t('signup.msg_input_password'));
        return;
      }
      if (this.pwd !== this.pwd_confirm) {
        showToast(this.$t('signup.msg_confirm_password'));
        return;
      }
    
      const pwdHash = sha256(this.pwd);

      requestPost(
        'user/resetPasswordByLoginId',
        {
          login_id: this.email,
          new_password_hash: pwdHash,
        },
        response => {
          window.alert(_app.$t('reset_password.completed'));
          this.$emit('close');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    async onSendCertKey() {
      let _app = this;
      if (_app.email === '') {
        showToast(this.$t('signup.msg_input_email'));
        return;
      }
      if (this.invalidEmail) {
        showToast(this.$t('signup.invalid_id'));
        return;
      }

      requestPost(
        'emailVerify/sendCertKey',
        {
          email: this.email
        },
        response => {
          showToast(this.$t('signup.msg_sent_cert_key'));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    async onCheckCertKey() {
      let _app = this;
      if (_app.certKey === '') {
        showToast(this.$t('signup.msg_input_cert_key'));
        return;
      }

      requestPost(
        'emailVerify/checkCertKey',
        {
          email: this.email,
          cert_key: this.certKey,
        },
        response => {
          this.isEmailVerified = true;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
